// should the amount of translatable text increase drastically, consider using i8n instead

import guessLanguage, { parseLanguageFromLocale } from './services/guessLanguage'

type LocalizeKeys = 'listen' |
'translate' |
'forwardButton' |
'backButton' |
'nextButton' |
'prevButton' |
'togglePlaylist' |
'addToPlaylist' |
'share' |
'playlistTitle' |
'playbackSpeedTitle' |
'speedSelector' |
'play' |
'pause' |
'close'

type Localizations = Record<LocalizeKeys, string>
const localized: Record<string, Localizations> = {
  en: {
    listen: 'Listen',
    translate: 'Translate',
    forwardButton: 'Go forward 15 seconds',
    backButton: 'Go back 15 seconds',
    nextButton: 'Play next',
    prevButton: 'Play previous',
    togglePlaylist: 'Toggle playlist panel',
    addToPlaylist: 'Add to playlist',
    share: 'Share article',
    playlistTitle: 'Playlist',
    playbackSpeedTitle: 'Playback speed',
    speedSelector: 'Choose playback speed',
    play: 'Play',
    pause: 'Pause',
    close: 'Close voice control panel'
  },
  fi: {
    listen: 'Kuuntele',
    translate: 'Käännä',
    forwardButton: '15 sekuntia eteenpäin',
    backButton: '15 sekuntia taaksepäin',
    nextButton: 'Toista seuraava',
    prevButton: 'Toista edellinen',
    togglePlaylist: 'Näytä soittolista',
    addToPlaylist: 'Lisää soittolistalle',
    share: 'Jaa artikkeli',
    playlistTitle: 'Soittolista',
    playbackSpeedTitle: 'Puhenopeus',
    speedSelector: 'Valitse toistonopeus',
    play: 'Toista',
    pause: 'Pysäytä',
    close: 'Sulje ääniohjauspaneeli'
  },
  sv: {
    listen: 'Lyssna',
    translate: 'Översätt',
    forwardButton: 'Gå framåt 15 sekunder',
    backButton: 'Gå tillbaka 15 sekunder',
    nextButton: 'Spela nästa',
    prevButton: 'Spela föregående',
    togglePlaylist: 'Växla spellistpanel',
    addToPlaylist: 'Lägg till spellistan',
    share: 'Dela artikel',
    playlistTitle: 'Spellist',
    playbackSpeedTitle: 'Hastighet',
    speedSelector: 'Välj uppspelningshastighet',
    play: 'Spela',
    pause: 'Paus',
    close: 'Stäng röstkontrollpanelen'
  },
  et: {
    listen: 'Kuulama',
    translate: 'Tõlkima',
    forwardButton: 'Mine edasi 15 sekundit',
    backButton: 'Mine tagasi 15 sekundit',
    nextButton: 'Mängi edasi',
    prevButton: 'Esita eelmine',
    togglePlaylist: 'Esitusloendi paani sisselülitamine',
    addToPlaylist: 'Esitusloendisse lisamine',
    share: 'Jaga artiklit',
    playlistTitle: 'Esitusloendi',
    playbackSpeedTitle: 'Taasesituse kiirus',
    speedSelector: 'Valige taasesituse kiirus',
    play: 'Mängima',
    pause: 'Paus',
    close: 'Sulgege hääljuhtpaneel'
  },
  uk: {
    listen: 'Слухати',
    translate: 'Переводити',
    forwardButton: 'Перейти на 15 секунд вперед',
    backButton: 'Повернутися на 15 секунд назад',
    nextButton: 'Грати далі',
    prevButton: 'Відтворити попередній',
    togglePlaylist: 'Переключити панель списків відтворення',
    addToPlaylist: 'Додати в плейлист',
    share: 'Поділитися статтею',
    playlistTitle: 'Список відтворення',
    playbackSpeedTitle: 'Швидкість відтворення',
    speedSelector: 'Виберіть швидкість відтворення',
    play: 'Грати',
    pause: 'Пауза',
    close: 'Закрити панель голосового управління'
  },
  es: {
    listen: 'Escuchar',
    translate: 'Traducir',
    forwardButton: 'Avanza 15 segundos',
    backButton: 'Retrocede 15 segundos',
    nextButton: 'Juega a continuación',
    prevButton: 'Jugar anterior',
    togglePlaylist: 'Alternar panel de lista de reproducción',
    addToPlaylist: 'Añadir a la lista de reproducción',
    share: 'Compartir artículo',
    playlistTitle: 'Playlist',
    playbackSpeedTitle: 'Velocidad de reproducción',
    speedSelector: 'Elija la velocidad de reproducción',
    play: 'Jugar',
    pause: 'Pausa',
    close: 'Cerrar panel de control de voz'
  },
  ru: {
    listen: 'Слушать',
    translate: 'Перевести',
    forwardButton: 'Переход вперед на 15 секунд',
    backButton: 'Вернитесь на 15 секунд назад',
    nextButton: 'Играть дальше',
    prevButton: 'Играть предыдущую',
    togglePlaylist: 'Переключение панели плейлиста',
    addToPlaylist: 'Добавить в плейлист',
    share: 'Поделиться статьей',
    playlistTitle: 'Список воспроизведения',
    playbackSpeedTitle: 'Скорость воспроизведения',
    speedSelector: 'Выберите скорость воспроизведения',
    play: 'Играть',
    pause: 'Пауза',
    close: 'Закрыть голосовую панель управления'
  },
  no: {
    listen: 'Lytte',
    translate: 'Oversette',
    forwardButton: 'Gå fremover 15 sekunder',
    backButton: 'Gå 15 sekunder tilbake',
    nextButton: 'Spill neste',
    prevButton: 'Spill tidligere',
    togglePlaylist: 'Vis/skjul spilleliste panel',
    addToPlaylist: 'Legg til i spilleliste',
    share: 'Del artikkel',
    playlistTitle: 'Spilleliste',
    playbackSpeedTitle: 'Avspillingshastighet',
    speedSelector: 'Velg avspillingshastighet',
    play: 'Skuespill',
    pause: 'Pause',
    close: 'Lukk talekontrollpanelet'
  },
  ja: {
    listen: '聞く',
    translate: '翻訳',
    forwardButton: '15秒進む',
    backButton: '15秒戻る',
    nextButton: '次にプレイ',
    prevButton: '前のプレイ',
    togglePlaylist: 'プレイリストパネルの切り替え',
    addToPlaylist: 'プレイリストに追加',
    share: '記事を共有する',
    playlistTitle: 'プレイリスト',
    playbackSpeedTitle: '再生速度',
    speedSelector: '再生速度を選択する',
    play: '遊ぶ',
    pause: '休止',
    close: '音声コントロールパネルを閉じる'
  },
  fr: {
    listen: 'Écouter',
    translate: 'Traduire',
    forwardButton: 'Avancez de 15 secondes',
    backButton: 'Revenez 15 secondes en arrière',
    nextButton: 'Jouer suivant',
    prevButton: 'Jouer précédent',
    togglePlaylist: 'Basculer le panneau de liste de lecture',
    addToPlaylist: 'Ajouter à la playlist',
    share: 'Partager l’article',
    playlistTitle: 'Playlist',
    playbackSpeedTitle: 'Vitesse de lecture',
    speedSelector: 'Choisissez la vitesse de lecture',
    play: 'Jouer',
    pause: 'Pause',
    close: 'Fermer le panneau de commande vocale'
  },
  nl: {
    listen: 'Luisteren',
    translate: 'Vertalen',
    forwardButton: 'Ga 15 seconden vooruit',
    backButton: 'Ga 15 seconden terug',
    nextButton: 'Volgende afspelen',
    prevButton: 'Speel vorige',
    togglePlaylist: 'Afspeellijstpaneel in-/uitschakelen',
    addToPlaylist: 'Toevoegen aan afspeellijst',
    share: 'Deel artikel',
    playlistTitle: 'Afspeellijst',
    playbackSpeedTitle: 'Afspeelsnelheid',
    speedSelector: 'Afspeelsnelheid kiezen',
    play: 'Spelen',
    pause: 'Pauzeren',
    close: 'Sluit het spraakbedieningspaneel'
  },
  ar: {
    listen: 'استمع',
    translate: 'ترجمة',
    forwardButton: 'المضي قدما 15 ثانية',
    backButton: 'العودة 15 ثانية إلى الوراء',
    nextButton: 'العب بعد ذلك',
    prevButton: 'العب السابق',
    togglePlaylist: 'تبديل لوحة قائمة التشغيل',
    addToPlaylist: 'إضافة إلى قائمة التشغيل',
    share: 'مشاركة المقال',
    playlistTitle: 'التشغيل',
    playbackSpeedTitle: 'سرعة التشغيل',
    speedSelector: 'اختر سرعة التشغيل',
    play: 'لعب',
    pause: 'تورع',
    close: 'أغلق لوحة التحكم الصوتي'
  },
  zh: {
    listen: '听',
    translate: '翻译',
    forwardButton: '前进 15 秒',
    backButton: '返回 15 秒',
    nextButton: '接下来玩',
    prevButton: '播放上一个',
    togglePlaylist: '切换播放列表面板',
    addToPlaylist: '添加到播放列表',
    share: '分享文章',
    playlistTitle: '播放列表',
    playbackSpeedTitle: '播放速度',
    speedSelector: '选择播放速度',
    play: '玩',
    pause: '暂停',
    close: '关闭语音控制面板'
  }

}
export function findSupportedLanguage () {
  const languages = navigator.languages ?? [navigator.language ?? 'en']
  return languages.map(l => parseLanguageFromLocale(l)).filter(l => !!localized[l])[0] ?? 'en'
}
export type LocalizationMode = 'selected_or_browser' | 'selected_or_page' | 'browser' | 'page'
export function useLocalizations (localizationMode: LocalizationMode = 'selected_or_page'): Localizations {
  const language = guessLanguage(document, localizationMode)
  const ret = localized[language] || localized.en
  return ret
}
