import { h } from 'preact'
import { PlayButton } from './buttons/PlayButton'

import styled from 'styled-components'
import { usePlayerController } from '../../context/PlayerContext'
import { useAppConfig } from '../../context/ConfigContext'
import { MobileSpeedSelector } from './MobileSpeedSelector'
import { CloseButton } from './buttons/CloseButton'
import { ShareButton } from './buttons/ShareButton'
import { GoBackButton } from './buttons/GoBackButton'
import { ControlPanelPlay } from './symbols/ControlPanelPlay'
import { GoForwardButton } from './buttons/GoForward'
import { ToggleSpeedSelectorButton } from './buttons/ToggleSpeedSelectorButton'
import { GoogleTranslateTab } from '../../translator/components/GoogleTranslateTab'

export const Widget = styled.div<{ backgroundColor: string, shadowColor: string, height?: number }>`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${(props) => props.height ?? 70}px;
  background-color: ${(props) => props.backgroundColor};
  box-shadow: 0px 0px 25px ${(props) => props.shadowColor};
`

export const ButtonContainer = styled.div`
  position: absolute;
  transform: translateX(-50%);
  display: grid;
  grid-template-columns: 55px 45px 100px 45px 55px;
  left: 50%;
  height: 100%;
`

export function ReaderWidget () {
  const playerController = usePlayerController()
  const {
    controlPanelBackgroundColor,
    controlPanelShadowColor,
    shareButtonEnabled
  } = useAppConfig()

  if (!playerController.widgetOpen) { return null }

  return (
      <div>

        {playerController.speedSelectorOpen && (
          <MobileSpeedSelector/>
        )}

        <Widget
          backgroundColor={controlPanelBackgroundColor}
          shadowColor={controlPanelShadowColor}
        >
          <ButtonContainer>

            {shareButtonEnabled ? <ShareButton /> : <div/>}

            <GoBackButton />
            <PlayButton extraStyles={'margin-top: -26px; place-self: center;'}>
              <ControlPanelPlay />
            </PlayButton>
            <GoForwardButton />
            <ToggleSpeedSelectorButton/>
          </ButtonContainer>
          <GoogleTranslateTab position={"absolute"} offsetY={70}/>
          <CloseButton />
        </Widget>
      </div>
  )
}
