import { h } from 'preact'
import styled from 'styled-components'
import { useLocalizations } from '../../../localizations'
import { ShareSymbol } from '../symbols/ShareSymbol'

const Button = styled.button`
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  border: none;
  place-self: center start;
`

export function ShareButton () {
  const localizations = useLocalizations()

  const canShare = () => navigator.canShare

  const onShare = async () => {
    const url = new URL(window.location.href)
    url.searchParams.set('voice-intuitive-open', 'true')

    const shareData = {
      title: document.title,
      url: url.href
    }

    try {
      await navigator.share(shareData)
    } catch (err) {
      console.error(err)
    }
  }

  return canShare()
    ? (
    <Button
      aria-label={localizations.share}
      onClick={async () => await onShare()}
    >
      <ShareSymbol />
    </Button>
      )
    : (
    <div />
      )
}
